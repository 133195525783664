export const MenuItems = [


  {
    title: 'Catalogue',
    url: 'catalogue',
    cName: 'nav-links'
  },
  {
    title: 'About',
    url: 'about',
    cName: 'nav-links'
  },
  {
    title: 'Showroom',
    url: 'video',
    cName: 'nav-links'
  },
  {
    title: 'New Customer',
    url: 'newCustomer',
    cName: 'nav-links'
  },
  {
    title: 'Contact',
    url: 'contact',
    cName: 'nav-links'
  },
]